import React from 'react'
import Layout from '../layouts/layout.js'
import Helmet from 'react-helmet'
import Pagetitle from '../components/Pagetitle'

const About = () => (
  <Layout>
    <div>
      <Helmet title="About Wayward Media" />
      <Pagetitle title="About Wayward Media" />
      <p>Wayward Media was founded in 1999 and used as a trading name by myself Paul Kidd to showcase the various web development and art projects I have worked on in my career.</p>
      <p>The majoity of my time is now taken up by Thunderfix Hardware which is both an online retailer and brick and mortar store managed by myself.</p>
      <p>I do still find some time to work on select web development projects.</p>
    </div>
  </Layout>
)

export default About
